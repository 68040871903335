* {
  font-family: 'Roboto', sans-serif !important;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


@font-face {
  font-family: 'Liberation Sans';
  src: url('/public/fonts/LiberationSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibri';
  src: url('/public/fonts/Calibri100.ttf') format('truetype');
}
